/* Global Styles with Harmonized Colors */

/* SCSS Variables for Compile-time Manipulation */
$white: #fff;
$gray-light: #ddd;
$gray-medium: #ccc;
$gray-dark: #333;
$black: #000;

$blue-light: #009fe3;
$blue-medium: #007ac1;
$blue-dark: #005f99;

$orange: #ffa343;

$red-light: #dc3545;
$red-medium: #c82333; /* Darkened by 10% */
$red-dark: #b21f2d; /* Darkened by 18% */
$red-darkest: #991926; /* Darkened by 25% */

$green-lightest: #bff2a6;
$green-lighter: #a8e48c;
$green-light: #28a745;
$green-medium: #218838; /* Darkened by 10% */
$green-dark: #1c7430; /* Darkened by 15% */

$white-transparent: rgba(255, 255, 255, 0.7);

:root {
  --white: #{$white};
  --gray-light: #{$gray-light};
  --gray-medium: #{$gray-medium};
  --gray-dark: #{$gray-dark};
  --black: #{$black};

  --blue-light: #{$blue-light};
  --blue-medium: #{$blue-medium};
  --blue-dark: #{$blue-dark};

  --orange: #{$orange};

  --red-light: #{$red-light};
  --red-medium: #{$red-medium};
  --red-dark: #{$red-dark};
  --red-darkest: #{$red-darkest};

  --green-lightest: #{$green-lightest};
  --green-lighter: #{$green-lighter};
  --green-light: #{$green-light};
  --green-medium: #{$green-medium};
  --green-dark: #{$green-dark};

  --white-transparent: #{$white-transparent};
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
  color: $gray-dark;
}

button {
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: $blue-light;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:hover {
  background-color: $blue-medium;
}

button:active {
  background-color: $blue-dark;
}

button:disabled {
  background-color: darken($gray-medium, 15%);
  cursor: not-allowed;
}

button i {
  margin: 0;
}
